<template>
  <div class="w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600">
    <div
      class="
        px-6
        py-2
        rounded-lg
        flex
        justify-between
        items-center
        cursor-pointer
        p-3
      "
      @click="toggleAccordion"
      style="border-bottom: gray solid 1px"
    >
      <p class="text-dark text-left"><b>Réplicas</b></p>
      <span
        class="h-5 w-5 m-2 flex justify-center items-center transition"
        :class="{ 'transform rotate-180': accordionOpen }"
      >
        <img src="@/assets/icons/arrow-down.svg" alt="" />
      </span>
    </div>
    <div v-if="accordionOpen" class="py-3 px-6 pb-6">
      <div v-bind="$attrs" class="mt-5">
        <div
          class="
            w-full
            overflow-x-auto
            rounded-xl
            border border-gray-300
            bg-white
            users
          "
        >
          <a-table
            :columns="columns"
            rowKey="id"
            :data-source="complaint.replicas"
            :pagination="false"
          >
            <div
              class="w-11/12 grid grid-cols-1 gap-2"
              slot="expandedRowRender"
              slot-scope="record"
            >
              <BaseTextarea
                ref="describe"
                id="describe"
                placeholder="Mensaje"
                v-model="record.argumento"
                :disabled="true"
                maxlength="1000"
              />
              <BaseDownload
                v-for="(option, index) in record.archivos"
                :value="option.name_file"
                :key="index"
                :url_download="option.file"
              />
            </div>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["complaint"],
  data: () => ({
    columns: [
      {
        title: "Fecha de creción",
        dataIndex: "fecha_creacion",
        key: "fecha_creacion",
      },
    ],
    accordionOpen: false,
  }),
  methods: {
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    },
  },
};
</script>